window.docsearch = require('docsearch.js');

import 'alpinejs';

import hljs from 'highlight.js';

hljs.registerLanguage('json', require('highlight.js/lib/languages/json'));
hljs.registerLanguage('php', require('highlight.js/lib/languages/php'));

document.querySelectorAll('pre code').forEach((block) => {
    hljs.highlightBlock(block);
});
